var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.errorMessages.length ? _c('div', {
    staticClass: "rerrormessage"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', {
    staticClass: "d-flex justify-start align-center"
  }, [_c('v-icon', {
    staticClass: "rerrormessage--alerticon",
    attrs: {
      "large": ""
    }
  }, [_vm._v(" mdi-alert-circle ")]), _c('v-divider', {
    staticClass: "mx-3",
    attrs: {
      "vertical": ""
    }
  }), _vm._l(_vm.errorMessages, function (error) {
    return _c('small', {
      key: error,
      staticClass: "rerrormessage--message mr-2"
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  })], 2), _c('div', {
    staticClass: "rerrormessage--closeicon"
  }, [_c('v-btn', {
    staticClass: "rerrormessage--closebutton",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.onClickClose
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v(" mdi-close ")])], 1)], 1)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }