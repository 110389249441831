<template>
  <v-btn
    class="rbutton elevation-0"
    :color="color"
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
    :type="type"
    :fab="fab"
    :attrs="attrs"
    :small="small"
    v-on="on"
    @click="onClick"
  >
    <v-icon
      v-if="icon"
      data-testid="icon"
      small
    >
      {{ icon }}
    </v-icon>
    <slot>
      {{ label }}
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'RButton',
  props: {
    label: {
      type: String,
      default: 'Submit',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    fab: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
    },
    on: {
      type: Object,
    },
    attrs: {
      type: Object,
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style>
.rbutton:not(.v-btn--round) {
  border-radius: var(--r-border-radius);
  letter-spacing: 0;
}
.v-btn__content {
  text-transform: none;
}
.v-btn--fab.v-size--small {
  width: 28px;
  height: 28px;
}
</style>
