<template>
  <div
    v-if="errorMessages.length"
    class="rerrormessage"
  >
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex justify-start align-center">
        <v-icon
          large
          class="rerrormessage--alerticon"
        >
          mdi-alert-circle
        </v-icon>
        <v-divider
          vertical
          class="mx-3"
        />
        <small
          v-for="error in errorMessages"
          :key="error"
          class="rerrormessage--message mr-2"
        >
          {{ error }}
        </small>
      </div>
      <div class="rerrormessage--closeicon">
        <v-btn
          icon
          class="rerrormessage--closebutton"
          @click="onClickClose"
        >
          <v-icon size="20">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RErrorMessage',
  props: {
    errors: Array,
    default: [],
  },
  data: () => ({
    closed: false,
  }),
  computed: {
    errorMessages() {
      if (this.closed) {
        return ''
      }

      return this.errors.filter((error) => !!error)
    },
  },
  methods: {
    onClickClose() {
      this.closed = true
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.rerrormessage {
  padding: 15px 15px;
  background-color: var(--r-light-grey);
}

.rerrormessage--alerticon {
  color: var(--r-error-color);
}

.rerrormessage--message {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--r-carbon-color);
  opacity: 0.75;
}
.rerrormessage--message::first-letter {
  text-transform: capitalize;
}

</style>
