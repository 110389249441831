<template>
  <div class="admin--clients">
    <r-page-header
      class="admin--clientsheader mb-16 mt-8"
      :label="$t('admin.clients.title')"
    >
      <template v-slot:end>
        <r-button
          label="Add Client"
          @click="$router.push('/admin/clients/add')"
        />
      </template>
    </r-page-header>
    <div>
      <r-error-message
        v-if="fetchClientsError"
        class="admin--clients--errormessage"
        :errors="[fetchClientsError]"
      />
      <v-row class="py-5">
        <v-col
          cols="auto"
          class="ml-auto"
        >
          <r-search-input
            :value="search"
            class="mr-2"
            style="width:300px"
            @change="onChangeSearch"
          />
        </v-col>
      </v-row>
      <div
        v-if="search"
        class="mb-4"
      >
        <div
          class="admin--clients--chip"
        >
          <span class="admin--clients--label">
            {{ search }}
          </span>
          <v-icon
            class="admin--clients--icon"

            @click="onRemove"
          >
            close
          </v-icon>
        </div>
      </div>
      <r-table
        class="clients--table"
        :columns="tableColumns"
        :rows="adminClients"
        :total-rows="totalRecords"
        :page="page"
        :page-size="pageSize"
        :loading="fetchingClients"
        @change:page="({ value }) => setPage(value)"
        @change:page-size="onChangePageSize"
        @change:page-size-all="onChangePageSizeAll"
        @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
        @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
      >
        <template v-slot:item.name="{ item }">
          <div class="text-truncate">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.clientId="{ item }">
          <router-link
            :to="`/admin/clients/${item.clientId}`"
          >
            {{ item.clientId }}
          </router-link>
        </template>
        <template v-slot:item.domainIds="{ item }">
          <div>
            {{ item.domainIds.length }}
          </div>
        </template>
        <template v-slot:item.appFeatures="{ item }">
          <r-badge
            :name="item.appFeatures.length.toString()"
            :title="generateFeatures(item.appFeatures)"
          />
        </template>
        <template v-slot:item.suspended="{ item }">
          <span>{{ item.suspended ? 'Yes': 'No' }}</span>
        </template>
        <template v-slot:item.lastLogin="{ item }">
          <span v-if="item.lastLogin">{{ formatDate(item.lastLogin) }}</span>
          <span v-else>Never</span>
        </template>
        <template v-slot:item.scrapeCadence="{ item }">
          <span v-if="item.scrapeCadence">{{ item.scrapeCadence }}</span>
          <span v-else>Not set</span>
        </template>
      </r-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RPageHeader from '@/components/library/molecules/RPageHeader.vue'
import RButton from '@/components/library/atoms/RButton.vue'
import RTable from '@/components/library/organisms/RTable.vue'
import RSearchInput from '@/components/library/molecules/RSearchInput.vue'
import RBadge from '@/components/library/atoms/RBadge.vue'
import RErrorMessage from '@/components/library/atoms/RErrorMessage.vue'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'

export default {
  name: 'Clients',
  components: {
    RErrorMessage,
    RBadge,
    RSearchInput,
    RTable,
    RButton,
    RPageHeader,
  },
  data() {
    return {
      clientsMapped: [],
      tableColumns: [
        {
          key: 'clientId',
          label: 'Client ID',
          hideable: false,
          sortable: true,
          width: 150,
        },
        {
          key: 'name',
          label: 'Name',
          hideable: true,
          sortable: true,
          width: 200,
        },
        {
          key: 'suspended',
          label: 'Suspended',
          hideable: true,
          sortable: true,
          width: 80,
        },
        {
          key: 'lastLogin',
          label: 'Last login',
          hideable: true,
          sortable: true,
          width: 120,
        },
        {
          key: 'maxUrls',
          label: 'Max. URLs',
          hideable: true,
          sortable: true,
          width: 120,
        },
        {
          key: 'domainIds',
          label: 'Domains',
          hideable: true,
          sortable: true,
          width: 120,
        },
        {
          key: 'scrapeCadence',
          label: 'Scrape Cadence',
          hideable: true,
          sortable: true,
          width: 50,
        },
        {
          key: 'appFeatures',
          label: 'App features',
          hideable: true,
          sortable: true,
          hidden: true,
          width: 50,
        },
      ],
      debouncedFetchData: null,
      adminClients: [],
      totalClients: 0,
      page: 1,
      sort: 'clientId',
      sortDir: 'asc',
      pageSize: 50,
      search: '',
    }
  },
  computed: {
    ...mapState('admin', [
      'clients',
      'fetchingClients',
      'fetchClientsError',
      'totalRecords',
    ]),
  },

  watch: {
    clients(items) {
      this.adminClients = [...items]
    },
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage(1)
      this.$data.debouncedFetchData()
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
  },
  beforeMount() {
    this.fetchData()
  },

  methods: {
    ...mapActions('admin', [
      'fetchClients',
    ]),
    fetchData() {
      const {
        page,
        sort,
        sortDir,
        pageSize,
        search,
      } = this

      let payload = { page, pageSize, search }

      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchClients(payload)
    },
    onChangePageSize({ value }) {
      this.setPage(1)
      this.setPageSize(value)
    },
    onChangePageSizeAll() {
      this.setPage(1)
      this.setPageSize(this.totalRecords)
    },
    setPage(page) {
      this.page = page
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize
    },
    setSort({ sort, sortDir }) {
      const mappableColumns = { domainIds: 'numDomains', appFeatures: 'numAppFeatures' }
      if (Object.keys(mappableColumns).includes(sort)) {
        this.sort = mappableColumns[sort]
      } else {
        this.sort = sort
      }
      this.sortDir = sortDir
    },
    onChangeSearch({ value }) {
      this.search = value
    },
    generateFeatures(features) {
      return features.map((it) => it.name).join(', ')
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onRemove() {
      this.search = ''
    },
  },
}
</script>

<style scoped>
.admin--clients--chip {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 6px;
  color: var(--r-text-color);
  background-color: var(--r-light-grey);
  border-radius: var(--r-border-radius);
  position: relative;
}
.admin--clients--label {
  line-height: 1;
  font-size: 0.92rem;
}
.admin--clients--icon {
  position: absolute;
  top: -7px;
  right: -6px;
  cursor: pointer;
}
</style>
