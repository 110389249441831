var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nolistingresults--container"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.messages.noListingResults')) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }