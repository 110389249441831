<template>
  <v-row
    class="rpageheader mx-0 pb-1"
    align="center"
  >
    <router-link
      v-if="previousroute"
      :to="previousroute"
      class="mr-2"
    >
      <v-icon
        large
        color="black"
      >
        arrow_back
      </v-icon>
    </router-link>
    <span
      class="rpageheader--label"
      :class="{ title: isTitle, 'sub-title': isSubTitle }"
    >
      {{ label }}
    </span>
    <slot name="postlabel" />
    <div class="end-slot-wrapper">
      <slot name="end" />
    </div>
  </v-row>
</template>

<script>
import { PAGE_HEADER_TYPES } from '@/utils/constants'

export default {
  name: 'RPageHeader',
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: PAGE_HEADER_TYPES.TITLE,
    },
    previousroute: String,
  },
  computed: {
    isTitle() {
      return this.type === PAGE_HEADER_TYPES.TITLE
    },
    isSubTitle() {
      return this.type === PAGE_HEADER_TYPES.SUB_TITLE
    },
  },
}
</script>

<style scoped>
.rpageheader {
  border-bottom: 1px solid var(--r-border-color);
}
.rpageheader h1 {
  font-weight: 500;
  font-size: 2.461538rem;
  color: var(--r-dark-grey);
  letter-spacing: -0.016px;
}
.end-slot-wrapper {
  align-items: center;
}
.title {
  font-weight: 500;
  font-size: 32px !important;
  line-height: 40px;

  letter-spacing: -0.016px !important;

  color: var(--r-carbon-color);
}

.sub-title {
  font-weight: 700;
  font-size: 17px !important;
  line-height: 24px;

  letter-spacing: -0.02px !important;

  color: var(--r-carbon-color);
}

/deep/ a {
  text-decoration: none;
}
/deep/ .end-slot-wrapper .v-messages {
  display: none;
}
/deep/ .end-slot-wrapper .v-text-field__details {
  min-height: auto;
}
.end-slot-wrapper {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}
</style>
