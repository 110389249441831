/* eslint-disable */

const nodeHasAnyClass = (node, classes) => {
  return node.classList && classes.find((className) => node.classList.contains(className))
}

export default (targetNode, exclusions) => {
  do {
    if (exclusions.classes && nodeHasAnyClass(targetNode, exclusions.classes)) {
      return false
    }
    if (exclusions.el && targetNode === exclusions.el) {
      return false
    }
  } while ((targetNode = targetNode.parentNode) !== null)
  return true
}
