var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "rsearchinput",
    attrs: {
      "placeholder": _vm.placeholder,
      "value": _vm.value,
      "prepend-inner-icon": "search",
      "outlined": "",
      "dense": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.onPressEnter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }