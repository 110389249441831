<template>
  <div class="nolistingresults--container">
    {{ $t('global.messages.noListingResults') }}
  </div>
</template>

<script>
export default {
  name: 'NoListingResults',
}
</script>

<style scoped>
.nolistingresults--container {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  color: #3F3F3F;
  font-weight: 700;
  font-size: 13px;
  padding: 8px 16px;
  border-radius: var(--r-border-radius);
}
</style>
