<template>
  <v-chip
    :color="color"
    :title="name"
    :removable="false"
    :style="{'color': textColor}"
    class="rbadge v-chip--container"
    @click="onClick"
  >
    <v-icon
      v-if="!!prependIcon"
      class="mr-1 rbadge--prependicon"
    >
      {{ prependIcon }}
    </v-icon>
    <span class="text-truncate">
      {{ name }}
    </span>
  </v-chip>
</template>

<script>
export default {
  name: 'RBadge',
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    isBackroundColorLight() {
      const bgColor = this.color
      if (!bgColor) {
        return true
      }
      const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 9) : bgColor
      const r = parseInt(color.substring(0, 2), 16) // hexa to red
      const g = parseInt(color.substring(2, 4), 16) // hexa to green
      const b = parseInt(color.substring(4, 6), 16) // hexa to blue
      const a = parseInt(color.substring(6, 8), 16) // hexa to alpha(opacity)
      let brightness = (r * 0.299) + (g * 0.587) + (b * 0.114)
      if (!Number.isNaN(a)) {
        brightness += (255 - a)
      }
      return brightness > 186
    },
    textColor() {
      return this.isBackroundColorLight ? '#000' : '#FFF'
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>
<style scoped>
.v-chip--container {
  border-radius: 9px !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
</style>
