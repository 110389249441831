<template>
  <v-text-field
    v-model="search"
    class="rsearchinput"
    :placeholder="placeholder"
    :value="value"
    prepend-inner-icon="search"
    outlined
    dense
    @keydown.enter.prevent="onPressEnter"
  />
</template>

<script>
export default {
  name: 'RSearchInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: this.$props.value,
    }
  },
  watch: {
    value(newVal) {
      this.search = newVal
    },
  },
  methods: {
    onPressEnter() {
      this.$emit('change', { value: this.$data.search })
    },
  },
}
</script>

<style scoped>
/deep/ .v-text-field__details {
  display: none;
}
/deep/ .v-text-field--outlined.v-input--has-state fieldset,
/deep/ .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
> .v-input__control > .v-input__slot fieldset {
  border-color: var(--r-border-color);
}

</style>
