const parseMonth = (month) => parseInt(month, 10) - 1

const dateStrToDate = (str) => {
  if (!str) {
    return ''
  }
  const [dateParts, timeParts] = str.split('T')
  const [year, month, day] = dateParts.split('-')
  if (!timeParts) {
    return Date.UTC(year, parseMonth(month), day)
  }

  const [hours, minutes, seconds] = timeParts.split(':')
  if (!seconds.includes('Z')) {
    const date = new Date(year, parseMonth(month), day, hours, minutes, seconds)
    return date.getTime()
  }

  const [second, miliseconds] = seconds.slice(0, -1)
  return Date.UTC(year, parseMonth(month), day, hours, minutes, second, miliseconds)
}

export default dateStrToDate
